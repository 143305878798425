@import "../../../styles/variables";

#campaign-detail-page {

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 55px;

    .title-main {
      @extend .h1;
      color: $grey-900;
      cursor: pointer;
    }

    img {
      margin: 0 22px;
    }

    .title-sub {
      @extend .navbar;
      color: $primary-500;
    }
  }
}