@import "../../../styles/variables";

#reset-password-page {
  background: $grey-200;

  .page-content {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $primary-500;
        color: $grey-100;
        padding: 35px;

        .title {
          @extend .h1;
          margin-bottom: 20px;
        }
      }

      #reset-password-form {
        padding: 35px;

        .form-input {
          border: 1px solid $primary-300;
        }

        .app-button {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {
      align-items: center;

      .content {
        max-width: 600px;

        .description {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        #reset-password-form {
          border: 1px solid $primary-500;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 20px;

          .app-button {
            width: 163px;
            margin-left: auto;
          }
        }
      }
    }
  }
}