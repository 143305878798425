@import "../../styles/variables";

#app-header {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 49px 32px;

  .menu-item {

    &:first-child {
      margin-right: auto;
      padding: 0;

      &:after {
        border: none;
      }
    }

    &.empty {

      .ant-menu-title-content {
        display: none;
      }
    }

    &.active {

      a {
        color: $kgb-primary-800;
      }
    }

    .ant-menu-title-content {
      margin-left: 5px;
    }

    .logo {
    }

    a {
      color: $grey-500;
    }
  }

  .submenu-item {
    color: $grey-500;

    &.active {
      color: $kgb-primary-800;
    }

    &.ant-menu-submenu-active {

      .ant-menu-submenu-title {
        color: $grey-500;
      }
    }

    .ant-menu-title-content {
      margin-left: 5px;
    }
  }
}

.ant-menu-submenu-popup {

  .ant-menu-sub {
    border-radius: 6px;
    padding-bottom: 4px;

    .ant-menu-item {
      padding: 4px 8px;

      .sub {
        color: $grey-500;
        display: flex;
        align-items: center;
        border-left: 6px solid $grey-500;

        .icon {
          margin: 0 8px;
        }
      }
    }
  }
}
