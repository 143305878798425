@import '../../../styles/variables';
@import "../AppModal";

.video-modal {
  @extend .app-modal;
  width: 60% !important;
  max-width: unset !important;

  .ant-modal-content {
    background: transparent;

    .ant-modal-body {
      padding: 0;

      .content {

        .player-wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          border-radius: 12px;
          overflow: hidden;

          #placeholder {
            width: 100%;
            opacity: 0;
          }

          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {

    &.ant-modal {
      max-width: unset !important;
    }
  }
}
