@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import './styles/bootstrap-grid.min.css';
@import './styles/buttons';
@import './styles/common';

@font-face {
  font-family: AvertaStd;
  src: url('/assets/fonts/AvertaStd/AvertaStd-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: AvertaStd;
  src: url('/assets/fonts/AvertaStd/AvertaStd-Semibold.otf');
  font-weight: 600;
}

@font-face {
  font-family: AvertaStd;
  src: url('/assets/fonts/AvertaStd/AvertaStd-Bold.otf');
  font-weight: 700;
}

html,
body,
#root {
  overflow: auto;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-loading {
  min-height: 100vh;
}

.w-100 {
  width: 100%;
}

.w-xl-100 {

  @media (min-width: 1200px) {
    width: 100%;
  }
}
