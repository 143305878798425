#order-invoice {

  .input-row {
    display: flex;

    .form-item {
      width: 50%;

      &:first-child {
        margin-right: 12px;
      }
    }
  }
}