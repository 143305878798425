@import "../../../../styles/variables";
.offer-wrapper {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $primary-200;
    border-radius: 8px;
    padding: 15px;

    .description {
      display: flex;
      flex-direction: column;

      .point {
        @extend .h3;
        color: $primary-500;
        margin-bottom: 6px;
      }

      .name {
        @extend .text-normal;
        color: $grey-500;
      }
    }

    .price-box {
      border-radius: 6px;
      padding: 4px;

      .price {
        @extend .h2;
        color: $primary-500;
      }
    }
  }

  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $grey-300;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 28px 12px 12px;
    .clear {
      @extend .button-text-m;
      cursor: pointer;
      color: $grey-400;
    }
  }
  .amount-error {
    margin-left: 245px;
  }
}

.app-button {
  margin-top: 12px;
}
.price-amount-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .input-box {
    display: flex;
    border: 1px solid #f0f0f0;
    height: 50px;
    border-radius: 6px;

    .adjust-button {
      @extend .no-select;
      cursor: pointer;
      background-color: #f9f9f9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      width: 48px;
      height: 48px;
      border-radius: 6px;

      &.decrement {
        color: $primary-500;
        border-radius: 6px 0 0 6px;
      }

      &.increment {
        color: $primary-500;
        border-radius: 0 6px 6px 0;
      }
    }

    .input {
      display: flex;
      align-items: center;
      border: none;
      box-shadow: none;
      width: 48px;
      height: 48px;

      input {
        text-align: center;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}

.ant-form {
  label {
    font-size: 16px;
    color: #858585;
    font-weight: 400;
    padding-bottom: 8px;
    display: block;
    &.offer-label,
    &.iban-label {
      margin-top: 32px;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .total-amount {
    color: $primary-500;
    font-size: 28px;
    font-weight: 500;
  }
}
.copy-section {
  position: relative;
  #copy-box {
    display: flex;
    justify-content: left;
    background-color: transparent;
    border: 1px solid #f0f0f0;
    width: 100%;
    font-size: 14px;
    color: #858585;
    padding: 12px;
    z-index: 9;
  }
  .icon {
    position: absolute;
    right: 7px;
    bottom: 10px;
  }
  .copy-box-copied-text {
    position: absolute;
    right: 4px;
    bottom: -18px;
  }
}

.chosen-amount {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #138656;
}

.border {
  margin: 25px 0;
  width: 100%;
height: 0px;

/* grey 300 */

border: 1px solid #F0F0F0;
}

.file-upload, .warning-file-upload {
  background: transparent;
  border-radius: 6px;
  margin-top: 55px;

  .file-upload-dragger {
    width: 100%;
    margin: 0;
    background: none;
    border: none;
    text-align: center;
  }

  .file-upload-image {
    margin: 20px auto;
    width: 56px;
    height: 56px;
  }

  .upload-text {
    margin-bottom: 30px;
  }

  span {
    margin: auto;
    border: none;
  }

  .upload-text {
    @extend .text-normal;

    .bold {
      font-weight: bold;
      color: $primary-500;
    }

  }

  .ant-upload-list {
    width: 70%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.file-upload {
  border: 1px solid $primary-500;
  .file-upload  {
    display: block;
  }
}

.warning-file-upload {
  border: 1px solid #ff4d4f;
  .file-upload  {
    display: block;
  }
}

.file-upload-text {
  display: none;
}

.warning-file-upload-text {
  display: block;
  font-size: 14px;
  color: #ff4d4f;
}


.company-form-second, .company-form-third  {
  .form-item-first {
    display: none;
  }
}

.second-tab-form-first, .second-tab-form-third {
  .second-tab {
    display: none;
  }
}
