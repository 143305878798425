@import "../../../../styles/variables";

#section-integration {
  .section-content {
    .section-right {
      padding-top: 64px;

      .method {
        .landing-button {
          margin-top: 8px;

          .arrow {
            margin-left: 21px;
            padding-bottom: 2px;
          }
        }

        .video-wrapper {
          position: relative;
          max-width: 650px;
          border-radius: 12px;
          overflow: hidden;
          margin-top: 48px;

          .player-wrapper {
            position: relative;
            border-radius: 12px;
            overflow: hidden;

            #placeholder {
              width: 100%;
              opacity: 0;
            }

            .react-player {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .section-content {
      .section-right {
        .method {
          .video-wrapper {
            margin-top: 120px;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;
        max-width: 570px;
      }
    }
  }
}
