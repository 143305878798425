@import "../../../../styles/variables";

#campaign-detail-table {

  .ant-table-cell {
    width: calc(100% / 6);

    .state {
      @extend .text-normal;
      border-radius: 4px;
      background: $error-red-200;
      color: $error-red-500;
      width: fit-content;
      margin: auto;
      padding: 4px;

      &.successful {
        background: $error-green-200;
        color: $error-green-500;
      }
    }
  }
}