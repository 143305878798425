@import "../../../../styles/variables";

#section-usage {
  .section-content {
    .section-right {
      padding-top: 84px;

      .usage {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin-bottom: 12px;
        }

        .firebase-key {
          @extend .text-xl-600;
          color: $dark-bulma-100;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;
      }
    }
  }
}
