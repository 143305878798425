@import "../../../styles/variables";

#contact-us-public-page {
  font-family: AvertaStd, -apple-system, sans-serif;

  &.page {
    padding: 0;
    background: $section;

    .page-content {
      padding: 0;
      $side-padding: 20px;

      #landing-header {
        padding: $side-padding;
      }

      #contact-us-page {
        width: unset;
        min-height: unset;

        .page-content {
          width: 100%;
          min-height: unset;
          padding: $side-padding;
          max-width: 720px;
          margin: auto;

          #page-title {
            color: $dark-bulma-100;
          }

          #contact-us-form {

            .label {
              color: $dark-bulma-100;
            }

            .form-input {
              background: transparent;
              color: $dark-bulma-100;
            }

            .ant-select-selector {
              border-color: $grey-300 !important;
              background: transparent;
              color: $dark-bulma-100;
            }

            .app-button {
              background: $dark-piccolo-100;

              span {
                color: $dark-gohan-100;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {

    &.page {

      .page-content {
        $side-padding: 32px;

        #landing-header {
          // 40px is placeholder for "?" button at landing page
          padding: $side-padding calc(#{$side-padding} + 40px) $side-padding $side-padding;
        }

        #contact-us-page {

          .page-content {
            padding: $side-padding;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {

    &.page {

      .page-content {
        $side-padding: 56px;

        #landing-header {
          $size: 1440px;
          min-width: $size;
          max-width: $size;
          margin: 0 auto;
          // 72px is placeholder for "?" button at landing page
          padding: $side-padding calc(#{$side-padding} + 72px) $side-padding $side-padding;
        }

        #contact-us-page {

          .page-content {
            padding: 20px $side-padding $side-padding;
          }
        }
      }
    }
  }
}
