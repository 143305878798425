@import "../../../../styles/variables";

.table-title {
  @extend .h2;
  color: $grey-900;
  margin-bottom: 22px;
}

#campaign-history-table {

  .ant-table-cell {
    width: calc(100% / 6);
  }

  .ant-table-tbody {

    .ant-table-row {

      .ant-table-cell {

        .action {
          cursor: pointer;

          &.action-bulk,
          &.action-api {
            @extend .text-normal;
            font-weight: 500;
            color: $primary-500;
          }
        }
      }
    }
  }
}