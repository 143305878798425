.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.navbar {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
}

.h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.text-primary {
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 42px;
}

.text-medium {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.tag {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-transform: uppercase;
}

.button-text-m {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.button-text-l {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

// Landing Page

.text-sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.text-sm-600 {
  @extend .text-sm;
  font-weight: 600;
}

.text-md {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.text-md-600 {
  @extend .text-md;
  font-weight: 600;
}

.text-lg {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.text-lg-600 {
  @extend .text-lg;
  font-weight: 600;
}

.text-xl {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.text-xl-600 {
  @extend .text-xl;
  font-weight: 600;
}

.text-2xl {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

.text-2xl-600 {
  @extend .text-2xl;
  font-weight: 600;
}

.text-3xl {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.text-3xl-600 {
  @extend .text-3xl;
  font-weight: 600;
}

.text-4xl {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
}

.text-4xl-600 {
  @extend .text-4xl;
  font-weight: 600;
}

.text-5xl {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -1px;
}

.text-5xl-600 {
  @extend .text-5xl;
  font-weight: 600;
}

.text-6xl {
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -1.5px;
}

.text-6xl-600 {
  @extend .text-6xl;
  font-weight: 600;
}


.text-8xl {
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 76px;
  letter-spacing: -2.5px;
}

.text-8xl-600 {
  @extend .text-8xl;
  font-weight: 600;
}
