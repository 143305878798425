@import "../Common/Form";
@import "../../styles/variables";

.app-modal {
  @extend .form;

  &.ant-modal {

    .ant-modal-content {
      border-radius: 8px;

      .content {
        display: flex;
        flex-direction: column;

        .icon {
          width: 56px;
          height: auto;
        }

        .title {
          @extend .navbar;
          color: $primary-500;
          margin-top: 24px;
          margin-bottom: 8px;
        }

        .text {
          @extend .h2;
          color: $grey-500;
        }

        .app-button {
          margin-top: 32px;
        }

        .button-wrapper {
          display: flex;

          .app-button {
            width: 50%;

            &:first-child {
              margin-right: 12px;
            }
          }
        }

        .loading-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 32px;

          .loading {
            font-size: 47px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {

    &.ant-modal {
      max-width: 400px !important;
    }
  }
}
