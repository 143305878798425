@import "../../../../styles/variables";

.feature-card {
  .content {
    display: flex;
    flex-direction: column;

    .icon {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
      }
    }

    .title {
      @extend .text-xl-600;
      color: $dark-bulma-100;
      margin: 16px 0 8px;
    }

    .description {
      @extend .text-xl;
      color: $dark-trunks-100;
    }
  }
}
