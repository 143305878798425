@import "../../../../styles/variables";

#landing-header {

  &.menu {
    background: transparent;
    border-bottom: none;

    .menu-item {

      &.button {
        padding: 0 4px;

        .user {
          padding: 0 15px !important;
        }
      }

      &:after {
        border: none;
      }

      &:first-child {
        padding: 0;
        margin-right: auto;
      }

      .firebase-key {
        @extend .text-lg-600;
        color: $dark-piccolo-100;
      }
    }

    .ant-menu-submenu {

      &:after {
        border: none;
      }
    }
  }

  @media (min-width: 1200px) {

    &.menu {

      .menu-item {
        padding: 0 12px;
      }
    }
  }

  @media (min-width: 1440px) {

    &.menu {

      .menu-item {
        padding: 0 28px;
      }
    }
  }
}

.drawer-popup {
  width: 210px;

  .ant-menu {
    background: $dark-hit-100;
    border: 1px solid rgba(105, 227, 145, 0.32);
    box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    padding: 0 6px 10px;

    .ant-menu-item {
      background: transparent !important;

      &.button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        margin-top: 28px;

        .ant-menu-title-content {
          width: 100%;

          .landing-button {
            width: 100%;

            a,
            .firebase-key {
              margin-left: 0;
            }
          }
        }
      }

      .ant-menu-title-content {
        display: flex;
        align-items: center;

        a,
        .firebase-key {
          @extend .text-md;
          line-height: 40px;
          color: $dark-goten-100;
        }
      }
    }
  }
}
