@import '../../../styles/variables';
@import "../AppModal";

.order-success-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .package-info {
          display: flex;
          background: $primary-100;
          border: 1px solid $grey-200;
          border-radius: 6px;
          padding: 22px 10px 22px 25px;
          margin-top: 8px;

          .package {
            @extend .h2;
            color: $primary-400;
            margin-left: 15px;
            margin-right: auto;
          }

          .total {
            @extend .h2;
            color: $primary-400;
          }
        }

        .app-button {

          &.home-page {
            margin-top: 12px;
          }
        }
      }
    }
  }
}