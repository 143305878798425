@import "../../../styles/variables";

#side-user-card {
  cursor: pointer;
  display: flex;
  background: $kgb-primary-50;
  border: 1px solid $primary-400;
  border-radius: 6px;
  width: 100%;

  .indicator {
    width: 7px;
    background: $primary-500;
  }

  .side-user-card-content {
    display: flex;
    width: 100%;
    padding: 12px 10px 10px;

    .description {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: auto;

      .description-title {
        @extend .h2;
        color: $primary-500;
      }

      .description-text {
        @extend .text-normal;
        color: $grey-500;
      }
    }
  }
}
