@import "../../../styles/variables";

#landing-page {
  font-family:
    AvertaStd,
    -apple-system,
    sans-serif;
  width: 100%;
  min-height: 100vh;

  &.page {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    padding: 0;

    .page-content {
      width: 100%;
      min-height: 100vh;
      padding: 0;
      $side-padding: 20px;

      #landing-header {
        padding: $side-padding 0;
      }

      .section {
        display: flex;
        background: $section;
        padding: 64px $side-padding;

        .section-content {
          display: flex;
          width: 100%;

          .section-left {
            .content {
              display: flex;
              flex-direction: column;

              .header {
                @extend .text-xl-600;
                color: $dark-piccolo-100;
              }

              .title {
                @extend .text-4xl-600;
                color: $dark-bulma-100;
                margin: 24px 0;
              }

              .description {
                @extend .text-xl-600;
                color: $dark-trunks-100;
              }
            }
          }
        }
      }

      .divider {
        border-bottom: 1px solid #013201;
      }
    }
  }

  @media (min-width: 1200px) {
    &.page {
      .page-content {
        $side-padding: 32px;

        #landing-header {
          padding: $side-padding 0;
        }

        .section {
          padding: 64px $side-padding;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    &.page {
      .page-content {
        $side-padding: 56px;

        #landing-header {
          padding: $side-padding 0;
        }

        .section {
          padding: 64px $side-padding;

          .section-content {
            $size: calc(1440px - calc(#{$side-padding} * 2));
            min-width: $size;
            max-width: $size;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
