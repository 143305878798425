#home-page {

  .page-content {

    #account-balance {
      margin-top: 26px;
    }

    #empty-state-card {
      margin-top: 48px;
    }

    #create-campaign-card {
      margin-top: 16px;
    }
  }
}
