@import "../../../../styles/variables";

#section-goals {
  .section-content {
    .section-right {
      padding-top: 64px;

      .select-wrapper {
        display: flex;
        flex-direction: column;
        background: $dark-gohan-100;
        border: 1px solid $dark-gohan-100;
        border-radius: 12px;
        max-width: 400px;
        padding: 6px 0 0;
        margin-bottom: 32px;

        .label {
          @extend .text-sm;
          color: $dark-trunks-100;
          padding: 0 16px;
        }

        .select {
          .ant-select-selector {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 0 16px;

            .ant-select-selection-item {
              .firebase-key {
                @extend .text-md;
                color: $dark-bulma-100;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;
        max-width: 545px;
      }
    }
  }
}

.goal-dropdown {
  background: $dark-gohan-100;
  border-radius: 12px;
  padding: 0;

  .ant-select-item-option {
    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      background: $dark-goku-100;
    }

    &.ant-select-item-option-selected {
      .ant-select-item-option-content {
        color: $dark-piccolo-100;
      }
    }

    .ant-select-item-option-content {
      @extend .text-md;
      color: $dark-bulma-100;
      padding: 8px 0;
    }
  }
}
