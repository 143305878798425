@import "../../../../../styles/variables";

#try-bulk-step-msisdn-list {

  .app-input {
    margin-bottom: 30px;
  }

  .button-add {
    cursor: pointer;
    display: flex;
    margin: 0 auto 30px;
  }

  #try-bulk-buttons {
    margin-top: 24px;
  }

  @media (min-width: 512px) {

    #try-bulk-buttons {
      margin-top: 48px;
    }
  }
}
