@import '../../../../styles/variables';
@import "../../BottomSheetModal";

.try-code-modal-wrapper {
  display: flex;
  align-items: flex-end;

  .try-code-modal {
    @extend .bottom-sheet-modal;
    width: 100% !important;
    max-width: 100vw;
    margin: 0;

    .ant-modal-content {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .ant-modal-body {

        .content {

          .button-wrapper {
            display: flex;

            .landing-button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: 512px) {
    display: flex;
    align-items: center;

    .try-code-modal {
      max-width: 350px;
      margin: 8px auto;

      .ant-modal-content {
        border-radius: 16px !important;

        .ant-modal-body {

          .content {

            .button-wrapper {
              justify-content: flex-end;

              .landing-button {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}
