@import "variables";

.feature-tabs {

  .ant-tabs-nav {
    background: $dark-gohan-100;
    border-radius: 12px;
    margin-bottom: 32px !important;

    &::before {
      border: none !important;
    }

    .ant-tabs-nav-wrap {
      width: 100%;

      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 8px;

        .ant-tabs-tab {
          padding: 4px 0;
          margin: 0;

          .ant-tabs-tab-btn {
            @extend .text-md;
            color: $dark-trunks-100;
            padding: 4px 8px;
          }

          &.ant-tabs-tab-active {

            .ant-tabs-tab-btn {
              border: 1px solid $dark-piccolo-100;
              border-radius: 8px;
              color: $dark-piccolo-100;
            }
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }
  }

  .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-content-holder {
    display: none;
  }
}
