@import '../../../styles/variables';
@import "../AppModal";

.campaign-bulk-file-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .download-wrapper {
          margin-top: 55px;

          .download {
            width: 100%;
            border-color: $primary-500;
            margin-top: 0;

            span {
              color: $primary-500;
            }
          }
        }

        .file-upload {
          background: transparent;
          border: 1px solid $primary-500;
          border-radius: 6px;
          margin-top: 55px;

          .upload-text {
            @extend .text-normal;

            .bold {
              font-weight: bold;
              color: $primary-500;
            }

          }
        }

        .file-uploaded {
          display: flex;
          flex-direction: column;
          border: 1px solid $primary-500;
          border-radius: 8px;
          padding: 16px;
          margin-top: 48px;

          .line-info {
            display: flex;

            .details {
              display: flex;
              flex-direction: column;
              margin-left: 15px;
              margin-right: auto;

              .file-name {
                @extend .button-text-m;
                color: $grey-900;
              }

              .file-size {
                @extend .text-normal;
                color: $grey-400;
              }
            }

            .file-delete {
              cursor: pointer;
              margin-top: 4px;
              margin-bottom: auto;
            }
          }

          .line-progress {
            display: flex;
            align-items: center;

            .progress {
              padding-left: 52px;

              .ant-progress-text {
                color: #7F56D9;

                span {
                  margin-left: 15px;
                }
              }
            }

            .percent {
              @extend .tag;
              color: $grey-900;
              margin-left: 12px;
              margin-right: 20px;
            }

            .checkmark {
              width: 16px;
              height: 16px;
            }
          }
        }

        .file-uploaded-result {
          display: flex;
          flex-direction: column;
          border-radius: 6px;
          margin-top: 16px;
          overflow: hidden; // hide inner corners

          &.success {
            $main: $error-green-500;
            $background-data: $error-green-200;
            $background-info: $error-green-100;
            border: 1px solid $main;

            .result-data {
              background: $background-data;
              border-bottom: 1px solid $main;

              .result-data-package {
                border-right: 2px solid $main;
              }

              .result-data-valid {

                .result-data-valid-line,
                .result-data-valid-text {
                  color: $main;
                }
              }
            }

            .result-info {
              background: $background-info;

              .result-description {

                .result-description-title {
                  color: $main;
                }
              }
            }
          }

          &.warn {
            $main: $error-orange-500;
            $background-data: $error-orange-200;
            $background-info: $error-orange-100;

            border: 1px solid $main;

            .result-data {
              background: $background-data;
              border-bottom: 1px solid $main;

              .result-data-package {
                border-right: 2px solid $main;
              }

              .result-data-valid {

                .result-data-valid-line,
                .result-data-valid-text {
                  color: $main;
                }
              }
            }

            .result-info {
              background: $background-info;

              .result-description {

                .result-description-title {
                  color: $main;
                }
              }
            }
          }

          &.error,
          &.fileError {
            $main: $error-red-500;
            $background-data: $error-red-200;
            $background-info: $error-red-100;

            border: 1px solid $main;

            .result-data {
              background: $background-data;
              border-bottom: 1px solid $main;

              .result-data-package {
                border-right: 2px solid $main;
              }

              .result-data-valid {

                .result-data-valid-line,
                .result-data-valid-text {
                  color: $main;
                }
              }
            }

            .result-info {
              background: $background-info;

              .result-description {

                .result-description-title {
                  color: $main;
                }
              }
            }
          }

          &.fileError {

            .result-data {
              display: none;
            }
          }

          .result-data {
            display: flex;
            padding: 8px 0;

            .result-data-package {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 50%;
              border-right: 2px solid $error-green-300;

              .result-data-package-amount {
                @extend .button-text-l;
                color: $grey-900;
              }

              .result-data-package-text {
                @extend .button-text-m;
                color: $grey-400;
              }
            }

            .result-data-valid {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 50%;

              .result-data-valid-line {
                @extend .button-text-l;
                font-weight: bold;
              }

              .result-data-valid-text {
                @extend .button-text-m;
              }
            }
          }

          .result-info {
            display: flex;
            padding: 18px 18px 26px;

            .result-description {
              display: flex;
              flex-direction: column;
              margin-left: 10px;

              .result-description-title {
                @extend .h2;
              }

              .result-description-text {
                @extend .text-normal;
                color: $grey-500;
              }
            }
          }
        }

        .button-wrapper {
          display: flex;

          .app-button {
            width: 50%;

            &:first-child {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}