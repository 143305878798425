@import '../../../styles/variables';
@import "../AppModal";

.campaign-type-usage-modal {
  width: 90vw !important;
  max-width: 1200px;
  padding-top: 24px;

  .ant-modal-content {
    border-radius: 8px;
    overflow: scroll;

    .ant-modal-body {
      padding: 0 0 20px;

      .content {
        border-radius: 8px;

        .usage {
          width: 100%;
          height: auto;
        }

        .app-button {
          margin: auto;
          width: fit-content;
          padding: 0 70px;
        }
      }
    }
  }
}