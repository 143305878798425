@import "../../../../styles/variables";

#campaign-approval {

  .form-item {
    width: 100%;
    padding-right: 100px;

    &:first-child {
      margin: 0 50px 8px 0;
    }

    &.text {
      width: 50%;
      padding: 0;
    }
    
    .form-input {
      margin-bottom: 4px;

      &.oth {
        display: flex;
        align-items: center;
  
        .ant-select-selector {
          height: 62px;
          border: 1px solid $grey-300;
          border-radius: 6px 6px 0 0 ;
  
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .note {
      @extend .text-normal;
      color: $grey-500;
      margin-bottom: 15px;
    }

    .link {
      @extend .button-text-l;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $primary-500;
    }
  }

  .other {
    width: 297px;
    height: 62px;
    top: -12px;
    border-radius: 0 0 6px 6px; 
  }

  .switch {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 20px;

    .title {
      @extend .h3;
      color: $grey-900;
      margin-bottom: 6px;
    }

    .sub-title {
      @extend .text-normal;
      color: $grey-500;
    }
  }

  .info {

    &.red {
      color: red;
    }
    &.disabled {
      display: none;
    }
  }
}