@import "../../../../styles/variables";

#order-payment {

  .card {

    .card-line {
      display: flex;
      justify-content: space-between;
      padding: 4px 12px 0;
      margin-bottom: 20px;

      .title {
        @extend .h3;
        color: $grey-900;
      }

      .card-info {
        @extend .text-normal;
        color: $primary-500;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .card-no {
          line-height: 1;
          margin-left: 4px;
        }
      }
    }
  }

  .no-card {

    .note {
      @extend .text-normal;
      color: $grey-500;
    }

    .switch {
      display: flex;
      justify-content: space-between;
      margin: 32px 0;
      padding: 0 16px;

      .text {
        @extend .h3;
        color: $grey-900;
      }
    }
  }

  // .money-transfer-button {
  //   @extend .button-text-l;
  //   color: $primary-color;
  //   display: flex;
  //   align-items: center;
  //   margin-left: 20px;
  //   cursor: pointer;
  // }

  .disabled-btn-wrapper{
    display: flex;

    .soon-wrapper{
      position: absolute;
      margin-left: 90px;
      
      .soon-text{
        background-color: $grey-400;
        color: $white;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }

    .continue-btn{
      margin-left: 8px;
    }
  }

  .disabled {
    color: $primary-color;
  }
}
