@import '../../../styles/variables';
@import "../AppModal";

.campaign-create-success-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .app-button {

          &.download {
            width: fit-content;
            margin-top: 8px;
            padding: 14px 12px;
          }

          &.homepage {
            border-color: $primary-500;

            span {
              color: $primary-500;
            }
          }
        }

        .link {
          @extend .button-text-l;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: $primary-500;
          margin-top: 46px;
        }

        #create-campaign-card {
          margin-top: 46px;
        }

        .api-key {
          display: flex;
          flex-direction: column;
          margin-top: 48px;

          .api-key-title {
            @extend .h3;
            color: $grey-400;
          }

          .api-key-value {
            @extend .h3;
            color: $grey-400;
            font-size: 13px;
            display: flex;
            align-items: center;
            border: 1px solid $grey-300;
            border-radius: 6px;
            padding: 2px 4px;
            margin: 8px 0;

            .prefix {
              margin-right: 4px;
            }

            .api-key-copy {
              padding: 10px 13px;
              margin-top: 0;
              margin-left: auto;
            }
          }

          .api-key-note {
            @extend .text-normal;
            color: $grey-400;
            display: flex;
            justify-content: space-between;

            .copied {
              color: transparent;
              text-align: right;
              margin-right: 6px;

              &.visible {
                color: $error-green-500;
              }
            }
          }
        }

        .api-documentation-link {
          margin-top: 36px;

          .text {
            @extend .button-text-l;
            color: $grey-100;
            margin: 0 6px;
          }
        }
      }
    }
  }
}