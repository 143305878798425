$primary-color: #0D5939;
$white: #FFFFFF;
$black: #000000;
$grey-100: #FFFFFF;
$grey-200: #F9F9F9;
$grey-300: #F0F0F0;
$grey-400: #858585;
$grey-500: #767676;
$grey-900: #121212;
$blue-200: #C7D6FE;
$blue-500: #094CF7;
$primary-50: #F9F5FF;
$primary-100: #D2F9E9;
$primary-200: #A6F2D2;
$primary-300: #79ECBC;
$primary-400: #1AB373;
$primary-500: #138656;
$primary-600: #0D5939;
$primary-700: #00331E;
$primary-disabled: #D9CEE4;
$kgb-primary-50: #E9FCF4;
$kgb-primary-100: #D2F9E9;
$kgb-primary-200: #A6F2D2;
$kgb-primary-600: #1AB373;
$kgb-primary-700: #138656;
$kgb-primary-800: #0D5939;
$kgb-primary-900: #062D1D;
$kgb-primary-950: #191919;
$error-red-100: #FEF3F2;
$error-red-200: #FEE4E2;
$error-red-300: #FDA29B;
$error-red-500: #F04438;
$error-orange-100: #FFFAEB;
$error-orange-200: #FEF0C7;
$error-orange-300: #FEC84B;
$error-orange-500: #F79009;
$error-green-100: #ECFDF3;
$error-green-200: #D1FADF;
$error-green-300: #6CE9A6;
$error-green-500: #12B76A;

// Landing Page
$dark-bulma-100: #FFFFFF;
$dark-beerus-100: #1F1F1F;
$dark-hit-100: #0B0B0B;
$dark-trunks-100: #999CA0;
$dark-goten-100: #FFFFFF;
$dark-gohan-100: #1F1F1F;
$dark-goku-100: #0B0B0B;
$dark-goku-120: #000000;
$dark-piccolo-100: #69E391;
$supportive-chichi-100: #FF4E64;
$supportive-dodoria-100: #D33030;
$supportive-krillin-10: #FFD98D;
$supportive-krillin-100: #FFC652;
$section: #0B0B0B;
