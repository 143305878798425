@import '../../../../styles/variables';
@import "../../BottomSheetModal";
@import "../../../Common/Form";

.try-bulk-modal-wrapper {
  display: flex;
  align-items: flex-end;

  .try-bulk-modal {
    @extend .bottom-sheet-modal;
    @extend .form;
    width: 100% !important;
    max-width: 100vw;
    margin: 0;

    .ant-modal-content {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .ant-modal-body {

        .content {

          .app-input {
            border: 1px solid $dark-piccolo-100;
            margin: 24px 0;

            &.error {
              border: 1px solid $supportive-dodoria-100;
            }
          }

          #try-bulk-buttons {
            display: flex;
            flex-direction: column;

            .landing-button {

              &:last-child {
                margin-top: 8px;
              }
            }

            .loading-wrapper {
              width: 100%;
              display: flex;
              justify-content: center;

              #loading {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 512px) {
    display: flex;
    align-items: center;

    .try-bulk-modal {
      max-width: 350px;
      margin: 8px auto;

      .ant-modal-content {
        border-radius: 16px !important;

        .ant-modal-body {

          .content {

            .app-input {
              margin-bottom: 48px;
            }

            #try-bulk-buttons {
              flex-direction: row;
              justify-content: flex-end;

              .landing-button {
                width: fit-content;
                order: 2;

                &:last-child {
                  margin-top: 0;
                  margin-right: 8px;
                  order: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
