@import '../../../styles/variables';
@import "../AppModal";
@import "../../Common/Form";

.credit-card-save-modal {
  @extend .app-modal;
  @extend .form;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .text {
          @extend .text-normal;
        }

        .sub-title {
          @extend .h3;
          color: $grey-400;
          margin-top: 32px;
        }

        .form {
          margin-top: 8px;

          .ant-form-item {
            margin-bottom: 0;

            .ant-form-item-explain {
              display: none;
            }
          }

          .card-data {
            display: flex;
            border: 1px solid $grey-300;
            border-radius: 6px;
            margin-top: 8px;

            .form-input {
              border: none;
              box-shadow: none;

              &.number {
                width: 225px;
                padding-right: 0;
              }

              &.expiry {
                width: 75px;
                text-align: center;
                padding: 0;
              }

              &.cvc {
                width: 50px;
                text-align: center;
                padding: 0;
              }
            }
          }
        }

        .switch {
          display: flex;
          justify-content: space-between;
          margin-top: 32px;

          .text {
            @extend .text-normal;
            color: $grey-500;
          }
        }
      }
    }
  }
}
