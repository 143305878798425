@import '../../../styles/variables';

.app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 14px 30px;
  height: 48px;

  span {
    @extend .button-text-l;
    color: $grey-100;
  }

  .ant-spin {
    display: flex;

    .ant-spin-dot-item {
      background-color: $white;
    }
  }

  &.primary {

    &:disabled {
      background-color: $primary-100 !important;
      opacity: 0.6;
      border: none;
    }

    span {
    }
  }

  &.default {
    background: transparent;

    span {
      color: $primary-color;
    }
  }

  &.ghost {
    border: 2px solid $grey-400;

    span {
      color: $grey-400;
    }
  }

  &.link {

    span {
      @extend .button-text-m;
      color: $primary-color;
    }
  }
}
