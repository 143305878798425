@import "../../../styles/variables";

#registration-page {
  background: $grey-200;

  .page-content {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      flex-direction: column;
      max-width: 850px;

      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .logo {
          margin-bottom: 20px;
        }
      }
    }
  }
}