@import '../../../styles/variables';
@import "../AppModal";

.side-user-add-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .note {
          @extend .text-normal;
          color: $primary-400;
          margin-top: 8px;
        }
      }
    }
  }
}
