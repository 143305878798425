@import "../../../../styles/variables";

#landing-footer {
  position: relative;
  padding: 24px 20px;
  background: $black;
  border: 1px solid rgba(105, 227, 145, 0.32);

  .mail-wrapper {
    display: flex;
    flex-direction: column;

    .title {
      @extend .text-3xl-600;
      color: $dark-bulma-100;
    }

    .link {
      @extend .text-lg;
      color: $dark-bulma-100;
    }
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 768px) {
    padding: 44px 56px;

    .link-4P {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
