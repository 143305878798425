@import "../../../../styles/variables";

#section-promotion {
  .section-content {
    .section-right {
      display: flex;
      padding-top: 72px;
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;

        .feature-card {
          .content {
            max-width: 210px;
          }
        }
      }
    }
  }
}
