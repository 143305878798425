@import "../../styles/variables";

#profile-form {
  width: 100%;
  padding: 20px;

  &.form-register {

    .section {

      .input-wrapper {

        &.disabled {
        }

        .input-box {

          .item {

            .input {
              border-bottom: 2px solid $primary-300;

              &:disabled {
              }

              &.ant-select-disabled {

                .ant-select-selector {
                }
              }

              .ant-input {
              }

              .ant-select-selector {
              }

              .ant-select-arrow {
              }
            }
          }
        }

        .icon-pen {
          display: none;
        }
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .title {
      @extend .h2;
      display: flex;
      color: $grey-900;
      margin-bottom: 8px;

      &.l{
        font-size: 20px;
        line-height: 52px;
      }

      .title-side-user-suffix {
        @extend .no-select;
        cursor: pointer;
        display: flex;
        width: fit-content;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: 2px;

        .text {
          @extend .button-text-m;
          color: $primary-600;
          margin-left: 4px;
        }
      }

    }

    .label {
      color:#767676;
      font-size: 20px;      

      &.ref{
        color:#138656;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .input-wrapper {
      display: flex;
      background: $grey-100;
      padding: 12px 15px;

      &.border-top {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &.border-bottom {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &.agency {
        padding: 12px 15px 0px 15px;
      }

      &.content{
        padding: 0px 0px 0 50px;
      }

      &.disabled {
        background: $grey-300;
      }

      &.none {
        display: none;
      }

      &.switch{
        border-radius: 6px;  
      }

      .checkbox {
        width: 20px;
      }

      .label {
        @extend .button-text-m;
        color: $grey-500;;
        margin-bottom: 15px;
        font-weight: 400;

        &.ref {
          margin-bottom: 23px;
        }

        &.bolder {
          font-size: 12px;
          font-weight: 700;
          color: #138656;
          margin-bottom: 3px;
        }

        &.bold {
          font-size: 28px;
          font-weight: 500;
          color: #138656;
          margin-bottom: 3px;
        }
      }
      

      .input-box {
        display: flex;
        flex-direction: column;
        width: 100%;

        .label {
          @extend .button-text-m;
          color: $grey-900;
          margin-bottom: 8px;

          &.bold {
            font-size: 12px;
            font-weight: 700;
            color: #138656;
          }
        }

        .item {
          margin-bottom: 0;

          .input {
            @extend .button-text-l;
            color: $grey-500;
            padding: 0;
            border: 1px solid transparent; // do not use "border: none" here;
            box-shadow: none;
            width: 100%;

            &.non-editable {

              &.ant-input-affix-wrapper-disabled {
                background-color: transparent;
              }

              &.ant-select-disabled {

                .ant-select-selector {
                  background-color: transparent;
                }
              }
            }

            &.editable {
              border-bottom: 2px solid $primary-300;
            }

            &:disabled {
              background-color: $grey-300;

              &.non-editable {
                background-color: transparent;
              }
            }

            &.ant-select-disabled {

              .ant-select-selector {
                background-color: $grey-300;
              }
            }

            .ant-input {
              @extend .button-text-l;
              color: $grey-500;
            }

            .ant-select-selector {
              border: none;
              box-shadow: none;
              color: $grey-500;
              padding: 0;
            }

            .ant-select-arrow {
              display: none;
            }
          }
          .file-upload, .warning-file-upload {
            margin-top: 0;
          }
          
          .file-upload {
            border:none;
          }
        }
      }

      .icon-pen,
      .icon-checkmark {
        cursor: pointer;
        margin-left: auto;
      }

      .icon-landmark{
       margin-right: 5px;
      }

      .icon-download {
        margin-left: auto;
      }

      .icon-file-delete,
      .icon-file-upload {
        cursor: pointer;
        margin: auto 0;
        color: $primary-500;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .icon-flag {
      margin-right: 7px;
      font-size: 10px;
    }

    .icon-copy {
      margin-left: 5.25px;
      font-size: 9.5px;
      cursor: pointer;
    }

    .side-user-error {
      @extend .text-normal;
      color: $error-red-500;
      text-align: right;
      margin-right: 2px;
      margin-bottom: 8px;
    }

    .side-users {
      display: flex;
      flex-direction: column;
      margin-top: 4px;

      .side-user {
        display: flex;
        background: $grey-100;
        border: 1px solid $grey-300;
        padding: 25px;

        &.border-top {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &.border-bottom {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        .full-name {
          @extend .button-text-l;
          color: $grey-500;
        }

        .delete {
          cursor: pointer;
          margin-left: auto;
        }
      }
    }
  }

  .app-button {
    margin: 0 auto;
    min-width: 180px;

    &.withdraw{
      min-width: 94px;
      margin-left: 20px;
      margin-right: 16px;
    }
  }

  @media (min-width: 768px) {
    padding: 20px 0;

    .section {
      margin-bottom: 48px;
    }
  }
}

.tooltip-sms-name {

  .ant-tooltip-arrow {

    .ant-tooltip-arrow-content {
      background-color: $grey-100;
    }
  }

  .ant-tooltip-inner {
    background-color: $grey-100;
    color: $grey-900;
    border-radius: 6px;
    padding: 12px 15px;

    .title,
    .text {
      font-size: 12px;
      line-height: 18px;
    }

    .title {
      font-weight: bold;
      color: $grey-900;
    }

    .text {
      color: $grey-500;
    }
  }
}
