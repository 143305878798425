@import "../../../styles/variables";
@import "../AppModal";

.order-success-modal {
  @extend .app-modal;

  .ant-modal-content {
    .ant-modal-body {
      .content {
        .app-button {
          &.home-page {
            margin-top: 12px;
          }
        }
      }
    }
  }

  .progress-bar {
    margin: 0;
    padding: 0;
  }
  .progress-bar li {
    list-style: none;
    display: inline-block;
    width: 33.33%;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat !important;
    background-size: initial;
  }
  .progress-bar li:before,
  .progress-second li:before {
    content: "";
    width: 56px;
    height: 56px;
    display: block;
    margin: 0 auto 10px auto;
  }
  .progress-bar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #12b76a;
    top: 28px;
  }
  .progress-0 {
    background-position-x: left;
    text-align: left;
    background: url(../../../assets/images/progress-icon-waiting.svg);
  }
  .progress-1,
  .progress-2 {
    background: url(../../../assets/images/progress-icon-success-waiting.svg);
  }
  .progress-third {
    .progress-0 {
      background: url(../../../assets/images/progress-icon-success.svg);
    }
    .progress-1 {
      background-position-x: center !important;
      background: url(../../../assets/images/progress-icon-success.svg);
    }
    .progress-2 {
      background: url(../../../assets/images/progress-icon-success.svg);
      background-position-x: right;
      text-align: right;
    }
  }
  .progress-1 {
    background-position-x: center;
    text-align: center;
  }
  .progress-0:after {
    left: 56px;
  }
  .progress-1:after {
    left: calc(50% + 28px);
  }
  .progress-2 {
    background-position-x: right;
    text-align: right;
  }
  .progress-bar li:last-child:after {
    content: none;
  }
  .progress-bar li.active {
    color: green;
  }
  .progress-bar li.active:before {
    border-color: green;
  }
  .progress-bar li.active + li:after {
    background-color: green;
  }
}
