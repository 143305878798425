@import '../../../styles/variables';
@import "../AppModal";

.side-user-delete-approval-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .button-wrapper {

          .app-button {

            &:nth-child(2) {
              border-color: $error-red-500;
              background: $error-red-500;
            }
          }
        }
      }
    }
  }
}
