@import "../../../../styles/variables";

#section-register {
  background: radial-gradient(52.47% 25.13% at 21.87% 39.24%, rgba(105, 227, 145, 0.25) 0%, rgba(0, 0, 0, 0) 100%),
    #000000 !important;

  .section-content {
    .section-left {
      .title {
        margin-top: 0 !important;
      }

      .button-wrapper {
        display: flex;
        margin-top: 24px;

        .landing-button {
          width: 148px;

          .arrow {
            margin-left: 21px;
            padding-bottom: 2px;
          }

          &.contact {
            background: transparent !important;

            span {
              color: $dark-piccolo-100;
            }

            .arrow {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .section-right {
      display: flex;
      padding-top: 72px;

      .feature-card {
        .content {
          max-width: 210px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    background: radial-gradient(23.44% 95.52% at 8.3% 72.87%, rgba(105, 227, 145, 0.25) 0%, rgba(0, 0, 0, 0) 100%),
      #000000 !important;

    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;
      }
    }
  }
}
