@import "../../styles/variables";

.bottom-sheet-modal {

  &.ant-modal {
    padding-bottom: 0;

    .ant-modal-content {
      background: $dark-gohan-100;
      border-radius: 16px;
      overflow: hidden;

      .content {
        display: flex;
        flex-direction: column;

        .title {
          @extend .text-xl-600;
          color: $dark-bulma-100;
          margin-bottom: 16px;
        }

        .text {
          @extend .text-lg;
          color: $dark-bulma-100;
          margin-bottom: 24px;
        }

        .note {
          @extend .text-lg;
          color: $dark-bulma-100;
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media (min-width: 768px) {

    &.ant-modal {

      .ant-modal-content {

        .content {

          .line-wrapper {
            margin-bottom: 24px;

            .line {
              position: absolute;
              left: -10%;
              right: -10%;
              border-top: 1px solid rgba(105, 227, 145, 0.32);
            }
          }

          .note {
            margin-bottom: 48px;
          }
        }
      }
    }
  }
}
