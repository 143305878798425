@import '../../styles/variables';

#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .icon {
    color: $primary-500;
  }
}