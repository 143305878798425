@import "../../../../styles/variables";

#login-form {

  .app-button {

    &.forgot-password {
      margin-top: 24px;
      border: 2px solid $grey-400;
      background-color: transparent;

      span {
        color: $grey-400;
      }
    }
  }
}
