#campaigns-page {

  #page-title {
    margin-bottom: 55px;

    &.with-campaigns {
      margin-bottom: 25px;
    }
  }

  #empty-state-card,
  #create-campaign-card {
    margin-bottom: 48px;
  }
}