@import "../../styles/variables";

.form {

  .ant-input {
    @extend .h3;
  }

  .form-input {
    @extend .h3;
    height: 62px;
    background: $grey-100;
    border: 1px solid $grey-300;
    border-radius: 6px;

    &.select {
      display: flex;
      align-items: center;

      .ant-select-selector {
        height: 62px;
        border: 1px solid $grey-300;
        border-radius: 6px;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .app-input {
    display: flex;
    align-items: center;
    background: $dark-gohan-100;
    border: 1px solid $dark-gohan-100;
    border-radius: 12px;
    padding: 7px 16px;

    &.error {
      border: 1px solid $supportive-dodoria-100;
    }

    &.valid {
      border: 1px solid $primary-color;
    }

    .prefix {
      @extend .text-lg;
      color: $dark-trunks-100;
      margin-right: 28px;
    }

    .suffix {
      margin-left: auto;
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .label {
        @extend .text-sm;
        color: $dark-trunks-100;
      }

      .input {
        margin-bottom: 0;

        .ant-form-item-control-input {
          min-height: 24px;

          .ant-form-item {
            margin-bottom: 0;
          }
        }

        .ant-input {
          @extend .text-md;
          font-size: 16px !important;
          color: $dark-bulma-100;
          background: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
        }

        .ant-form-item-explain {
          display: none;
        }
      }
    }
  }
}
