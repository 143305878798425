@import '../../../styles/variables';
@import "../AppModal";

.logout-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .button-wrapper {
          display: flex;

          .app-button {
            width: 50%;

            &:first-child {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}