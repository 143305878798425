#contact-us-form {

  .text-area {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .app-button {
    margin-top: 48px;
    width: 100%;
  }
}