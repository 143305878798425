@import '../../../styles/variables';
@import "../AppModal";

.forgot-password-modal {
  @extend .app-modal;

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .title {
          margin-top: 0;
        }

        .text {
          margin: 15px 0;
        }

        .input {
          border-radius: 8px;
          border: 1px solid $primary-500;
          font-size: 16px;
          height: 48px;
        }
      }
    }
  }
}