@import "../../../styles/variables";

#campaign-create-page {

  .page-content {

    #page-title {
      margin-bottom: 55px;
    }

    .create-campaign-form {
      border: none;

      .ant-collapse-item {
        border: none;

        &.ant-collapse-item-active {

          .ant-collapse-header {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .ant-collapse-header {
          @extend .h3;
          background: $grey-100;
          color: $grey-900;
          margin-bottom: 10px;
          border: 1px solid $grey-300;
          border-radius: 6px;
          padding: 25px 15px;

          .panel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 27px;

            .title {
              @extend .h3;
              color: $grey-900;
              margin-bottom: 6px;
            }

            .sub-title {
              @extend .text-normal;
              color: $grey-500;
            }

            .header-suffix {
              background: $primary-100;
              border-radius: 6px;
              margin-right: 50px;
              padding: 4px 8px;

              .text {
                @extend .tag;
                color: $primary-500;
              }
            }
          }
        }

        .ant-collapse-content {
          border: none;
          margin-bottom: 10px;
        }
      }
    }
  }
}