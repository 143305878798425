@import "variables";

.landing-button {
  background: $dark-piccolo-100 !important;
  color: $dark-gohan-100 !important;
  border: none !important;
  border-radius: 8px !important;
  height: 48px !important;
  padding: 0 20px !important;

  &:hover {
    opacity: 0.85;
  }

  &.secondary {
    background: $supportive-krillin-100 !important;
    color: $dark-gohan-100 !important;
  }

  &.outline {
    background: transparent !important;
    border: 1px solid $dark-piccolo-100 !important;
    color: $dark-piccolo-100 !important;

    a {
      color: $dark-piccolo-100 !important;

      span {
        color: $dark-piccolo-100 !important;
      }
    }
  }

  span {
    @extend .text-lg;
    font-weight: 600 !important;
  }

  a {
    @extend .text-lg;
    font-weight: 600 !important;
    color: $dark-gohan-100 !important;

    span {
      @extend .text-lg;
      font-weight: 600 !important;
      color: $dark-gohan-100 !important;
    }
  }
}
