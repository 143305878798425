@import "../../../styles/variables";

#faq-page {

  .page-content {
    width: 1034px !important;

    .page-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      #page-title {
        margin-bottom: 0;
      }

      .app-button {
        padding: 15px;
      }
    }

    .search {
      margin-top: 55px;

      .ant-input-prefix {
        margin-left: 5px;
        margin-right: 10px;
      }

      .ant-input-suffix {

        .anticon {
          font-size: 18px;
        }
      }
    }

    .faqs {
      margin-top: 48px;
      border: none;

      .ant-collapse-item {
        border: none;

        &.ant-collapse-item-active {

          .ant-collapse-header {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .ant-collapse-header {
          @extend .h3;
          background: $grey-100;
          color: $grey-900;
          margin-bottom: 10px;
          border: 1px solid $grey-300;
          border-radius: 6px;
          padding: 25px 15px;
        }

        .ant-collapse-content {
          @extend .text-normal;
          line-height: 20px;
          color: $grey-500;
          border: none;
          margin-bottom: 10px;
        }
      }
    }
  }
}