@import "../../../../styles/variables";

#section-kgb-app {
  .section-content {
    .section-left {
      .topics {
        display: flex;
        flex-direction: column;

        .topic {
          display: flex;
          align-items: center;
          margin-top: 24px;

          .text {
            @extend .text-2xl-600;
            color: $dark-bulma-100;
            margin-left: 16px;
          }
        }
      }
    }

    .section-right {
      display: flex;
      justify-content: center;
      padding-top: 72px;

      .kgb-app {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 64px;

        .phone {
          width: 130%;
          height: auto;
          margin-left: -27%;
        }

        .store-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            @extend .text-lg;
            font-weight: 600;
            color: $dark-piccolo-100;
            margin-bottom: 16px;
          }

          .links {
            display: flex;

            a {
              margin-bottom: 8px;

              &:first-child {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .section-content {
      .section-right {
        .kgb-app {
          .phone {
            width: 85%;
            height: auto;
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 545px;
        }
      }

      .section-right {
        padding-top: 0;

        .kgb-app {
          width: 100%;
          flex-direction: row;
          align-items: flex-end;
          margin-top: 0;

          .store-wrapper {
            position: absolute;
            right: 0;
            bottom: 90px;

            .links {
              flex-direction: column;

              a {
                &:first-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
