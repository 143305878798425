@import "../Common/Form";
@import "../../styles/variables";

#create-campaign-card {
  cursor: pointer;
  display: flex;
  background: $kgb-primary-50;
  border: 1px solid $primary-400;
  border-radius: 6px;
  width: 100%;

  .indicator {
    width: 7px;
    background: $primary-500;
  }

  .create-campaign-card-content {
    display: flex;
    width: 100%;
    padding: 14px 27px 12px 10px;

    .description {
      display: flex;
      flex-direction: column;
      margin-left: 9px;
      margin-right: auto;

      .description-title {
        @extend .h2;
        color: $primary-500;
      }

      .description-text {
        @extend .text-normal;
        color: $grey-500;
      }

      .description-balance {
        @extend .text-normal;
        color: $kgb-primary-800;
        display: flex;
        align-items: center;

        .balance {
          font-weight: bold;
        }
      }
    }
  }
}