@import "../../../../styles/variables";

#reward-msisdn-form {
  border: 1px solid $primary-300;
  border-radius: 6px;
  width: 680px;
  padding: 20px;

  .label {
    @extend .h2;
    color: $grey-900;
    margin-bottom: 15px;
  }

  .note {
    @extend .text-normal;
    color: $grey-400;
  }

  .form-item {

    &.w-50 {
      width: 50%;
    }
  }

  .offer-wrapper {
    display: flex;
    width: 420px;
  }

  .msisdn-wrapper {
    width: 430px;
  }
}