@import "../../../../styles/variables";

#section-banner {
  flex-direction: column;
  background: radial-gradient(57.07% 107.83% at 5.59% 9.96%, rgba(105, 227, 145, 0.5) 0%, rgba(0, 0, 0, 0.13) 100%),
    #000000 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .section-content {
    flex-direction: column;

    .section-left {
      .content {
        .main-title {
          @extend .text-5xl-600;
          color: $dark-bulma-100;
        }

        .title {
          @extend .text-4xl-600;
          color: $dark-bulma-100;

          .check {
            width: 30px;
            height: auto;
            padding-bottom: 8px;
            margin-left: 10px;
          }
        }

        .description {
          @extend .text-xl-600;
          color: $dark-trunks-100;
          margin-bottom: 24px;
        }

        .landing-button {
          width: 148px;

          .arrow {
            margin-left: 21px;
          }
        }
      }
    }

    .section-right {
      margin-top: 64px;
      text-align: center;

      .player-wrapper {
        position: relative;
        max-width: 650px;
        border-radius: 12px;
        overflow: hidden;

        #placeholder {
          width: 100%;
          opacity: 0;
        }

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .brands-note {
      display: flex;
      flex-direction: column;
      margin: 72px auto 88px;

      .note {
        @extend .text-xl-600;
        color: $dark-trunks-100;
        margin-bottom: 24px;
      }

      .brands {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 43px;
        width: 100%;

        img {
          width: 40px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .section-content {
      .section-left {
        .content {
          max-width: 100%;

          .main-title {
            font-size: 72px !important;
            line-height: 76px !important;
            letter-spacing: -2.5px !important;
          }

          .title {
            font-size: 56px !important;
            line-height: 64px !important;
            letter-spacing: -1.5px !important;

            .check {
              width: unset;
              height: unset;
              margin-left: 15px;
            }
          }
        }
      }

      .section-right {
        margin-top: 0;
      }

      .brands-note {
        align-items: flex-start;
        margin: 72px 0 88px;

        .brands {
          justify-content: flex-start;
        }
      }
    }
  }
}
