@import "../../styles/variables";

.table {

  .ant-table-thead {

    .ant-table-cell {
      @extend .button-text-m;
      background: $grey-300;
      text-align: center;
      padding: 13px 0;
    }
  }

  .ant-table-tbody {

    .ant-table-row {
      height: 72px;

      .ant-table-cell {
        @extend .text-medium;
        color: $grey-900;
        text-align: center;
        padding: 0;
      }
    }
  }
}
