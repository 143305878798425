@import '../../../styles/variables';
@import "../AppModal";

.pay-by-me-modal {
  @extend .app-modal;
  width: 360px !important;

  .ant-modal-content {

    .ant-modal-body {
      padding: 4px;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: center;

        #pay-by-me-iframe {
          border-radius: 6px;
        }
      }
    }
  }
}