@import '../../../styles/variables';
@import "../AppModal";
@import "../../Common/Form";

.credit-card-save-modal {
  @extend .app-modal;
  @extend .form;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-modal-content {

    .ant-modal-body {

      .content {

        .title {

          &.bold {
            font-size: 12px;
            font-weight: 700;
            color: #138656;
            margin-bottom: 3px;
          }

          &.large {
            font-size: 28px;
            font-weight: 500;
            color: #138656;
            margin-top: 0px;
          }
        }

        .text {
          @extend .text-normal;
          margin-top: 8px;
        }

        .sub-title {
          @extend .h3;
          color: $grey-400;
          margin-top: 27px;
        }

        .form {
          margin-top: 8px;

          .ant-form-item {
            margin-bottom: 0;

            .ant-form-item-explain {
              display: none;
            }
          }

          .card-data {
            display: flex;
            border: 1px solid $grey-300;
            border-radius: 6px;
            margin-top: 8px;

            .form-input {
              border: none;
              box-shadow: none;
              width: 320px;
            }
          }
        }

      }
    }
  }
}