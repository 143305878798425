@import "../../styles/variables";

#auth-header {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  background: $white;
  border: none;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 25px 32px;

  .app-button {
    width: 120px;
    border-color: $primary-color;

    span {
      color: $primary-color;
    }
  }

  .logo {
    position: absolute;
    right: 32px;
    width: 150px;
  }

  @media (min-width: 768px) {
    .logo {
      width: auto;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
