@import "../Common/Form";
@import "../../styles/variables";

#empty-state-card {
  width: 100%;
  padding: 24px;
  background: $grey-100;
  display: flex;
  flex-direction: column;

  .title {
    @extend .h2;
    color: $primary-500;
  }

  .text {
    @extend .text-normal;
    color: $grey-500;
    margin-top: 16px;

    .empty-text {
      @extend .text-normal;
      color: $grey-500;
    }
  }

  .app-button {
    width: fit-content;
    margin-top: 32px;
    padding: 14px;
  }
}