@import "../Common/Form";
@import "../../styles/variables";

#account-balance {
  display: flex;
  flex-direction: column;

  .balance-wrapper {
    display: flex;
    align-items: center;

    .balance {
      @extend .text-primary;
      display: flex;
      align-items: center;
      color: $primary-600;
      margin-right: 20px;

      .suffix {
        @extend .h2;
        color: $primary-600;
        margin-left: 4px;
      }

      &.empty {
        color: $grey-400;

        .suffix {
          color: $grey-400;
        }
      }
    }

    .button {
      cursor: pointer;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;

    .label {
      @extend .text-normal;
      color: $grey-500;
      margin-left: 4px;
    }
  }
}