@import "../../../../styles/variables";

#side-user-form {
  margin-top: 32px;

  .ant-form-item {
    margin-bottom: 16px;
  }

  .note {
    @extend .text-normal;
    color: $grey-400;
    margin-top: 32px;
    margin-bottom: 0;
  }
}
