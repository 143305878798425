@import "../../Common/Form";
@import "../../Common/Table";
@import "../../../styles/variables";

#app-layout {
  @extend .form;
  @extend .table;
  width: 100%;
  min-height: 100vh;
  background: $grey-200;
  display: flex;
  flex-direction: column;

  .page {
    flex-grow: 1;
    width: 100%;
    overflow-x: scroll;
    padding: 0 20px;

    .page-content {
      position: relative;
      width: 828px;
      padding-bottom: 24px;

      &.small {
        width: 667px;
      }

      &.large {
        width: 986px;
      }
    }

    @media (min-width: 1000px) {
      display: flex;
      justify-content: center;
    }
  }

  .app-button {

    &.faq-button {
      position: absolute;
      right: 24px;
      bottom: 24px;

      &:focus {
        background: $primary-500;
      }
    }
  }
}
