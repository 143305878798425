@import "../../../../styles/variables";

#copy-box {
  @extend .text-lg;
  color: $dark-piccolo-100;
  background: $dark-hit-100;
  border: 2px dashed $dark-piccolo-100;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  cursor: pointer;
}

.copy-box-copied-text {
  @extend .text-sm;
  font-style: italic;
  color: $dark-piccolo-100;
  display: flex;
  justify-content: flex-end;
}
