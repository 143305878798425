@import "../../Common/Form";

#auth-layout {
  @extend .form;
  width: 100%;
  min-height: 100vh;

  .page {
    width: 100%;
    min-height: 100vh;

    .page-content {
      width: 100%;
      min-height: 100vh;
    }
  }
}
