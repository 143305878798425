@import "../../../../styles/variables";

#campaign-package {

  .inputs {
    width: 450px;
  }

  .amount-warning {
    @extend .button-text-m;
    color: $grey-400;
    margin-bottom: 8px;
  }

  .form-item {

    &.w-50 {
      width: 50%;
    }

    .balance {
      @extend .button-text-m;
      color: $grey-400;
    }
  }
}