@import "../../../styles/variables";

#registration-waiting-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    background: $white;
    width: 100%;
    height: 100px;
    padding: 15px;
  }
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      @extend .h1;
      color: $primary-500;
    }

    .description {
      max-width: 450px;
      background-color: #d4d4d4;
      padding: 35px;
    }
  }

  @media (min-width: 768px) {

    .page-content {

      .description {
        border-radius: 7px;
      }
    }
  }
}