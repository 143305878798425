@import "../../../styles/variables";

#auth-page {
  display: flex;
  justify-content: center;

  .page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px !important;
    padding-bottom: 20px;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .form {
        flex-grow: 1;
        padding: 40px;

        .title {
          text-align: center;
        }

        .app-button {
          width: 100%;
        }
      }

      .link-auth-type {
        text-align: center;
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {
      padding-top: 110px !important;

      .form-wrapper {
        flex-direction: row;
        border-radius: 6px;
        box-shadow: 0 5px 15px 0 rgb(100 100 111 / 20%);
      }
    }

    #login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: $grey-200;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    #sign-up-form {
      background: $grey-300;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  @media (min-width: 1200px) {

    .page-content {

      .form-wrapper {

        .form {
          padding: 50px 80px;
        }
      }
    }
  }
}
