#reward-msisdn-page {

  .page-content {

    #account-balance {
      margin-top: 26px;
    }

    #empty-state-card,
    #reward-msisdn-form {
      margin-top: 48px;
    }
  }
}