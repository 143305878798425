@import "../../../styles/variables";

#activation-page {

  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      @extend .h1;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      padding: 0 20px;
      margin-bottom: 30px;

      .code-box {
        background: $grey-300;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 35px;

        .code-input {
          width: 70%;
          margin-top: 20px;
          text-align: center;
          font-size: 16px;
          height: 48px;
          background: $grey-100;
          border: 1px solid $grey-300;
          border-radius: 6px;
        }
      }

      .app-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .resend-button {
      @extend .h3;
      height: 30px;
    }
  }
}
