@import "../../../styles/variables";

#order-page {

  .page-content {

    .page-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      #page-title {
        margin-bottom: 0;
      }

      .history-link {
        padding: 12px;

        .icon {
          margin-right: 4px;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .order-form {
      margin-top: 55px;
      border: none;

      .ant-collapse-item {
        border: none;

        &.ant-collapse-item-active {

          .ant-collapse-header {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .ant-collapse-header {
          @extend .h3;
          background: $grey-100;
          color: $grey-900;
          margin-bottom: 10px;
          border: 1px solid $grey-300;
          border-radius: 6px;
          padding: 25px 15px;

          .panel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 27px;

            .header-suffix {
              background: $primary-100;
              border-radius: 6px;
              margin-right: 50px;
              padding: 4px 8px;

              .text {
                @extend .tag;
                color: $primary-500;
              }
            }
          }
        }

        .ant-collapse-content {
          border: none;
          margin-bottom: 10px;
        }
      }
    }
    .ant-select-selection-search-input {
      height: 60px;
    }

    .warning {
      .ant-input {
        color: red;
      }
    }
    
    .form-input {
      &.auto{
        margin-right: 4px;
      }
    }
  }
}