@import "../../../../styles/variables";

#order-package {

  .offer-wrapper {
    display: flex;
    flex-direction: column;
    width: 375px;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $kgb-primary-50;
      border: 1px solid $primary-200;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 15px;

      .description {
        display: flex;
        flex-direction: column;

        .point {
          @extend .h3;
          color: $primary-500;
          margin-bottom: 6px;
        }

        .name {
          @extend .text-normal;
          color: $grey-500;
        }
      }

      .price-box {
        background: $primary-300;
        border-radius: 6px;
        padding: 4px;

        .price {
          @extend .h2;
          color: $primary-500;
        }
      }
    }

    .amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $grey-300;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 28px 12px 12px;

      .clear {
        @extend .button-text-m;
        cursor: pointer;
        color: $grey-400;
      }

      .input-box {
        display: flex;

        .adjust-button {
          @extend .no-select;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          width: 48px;
          height: 48px;
          border-radius: 6px;

          &.decrement {
            background: transparent;
            color: $primary-500;
          }

          &.increment {
            background: $primary-500;
            color: $grey-100;
          }
        }

        .input {
          display: flex;
          align-items: center;
          border: none;
          box-shadow: none;

          input {
            text-align: center;
          }

          .ant-input-number-handler-wrap {
            display: none;
          }
        }
      }
    }

    .amount-error {
      margin-left: 245px;
    }
  }

  .app-button {
    margin-top: 12px;
  }
}
