@import "../../../../styles/variables";

#campaign-method {
  display: flex;
  flex-direction: column;

  .methods {
    display: flex;
    justify-content: space-between;

    .campaign-type {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      border: 1px solid $grey-300;
      border-radius: 6px;
      width: 186px;
      height: 420px;

      &.active {
        border: 1px solid $primary-400;

        .header {
          background: $primary-100;
          border-bottom: 1px solid $primary-400;
        }
      }

      .header {
        display: flex;
        align-items: center;
        padding: 14px 20px;
        border-bottom: 1px solid $grey-300;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .title {
          margin-left: 10px;
          margin-right: auto;
        }

        .selection {
          width: 16px;
          height: 16px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 9px 5px;

        .text {
          @extend .text-normal;
          color: $grey-500;
          padding: 16px 5px;
        }

        .features {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;

          .feature {
            display: flex;
            width: 100%;
            border-radius: 6px;
            padding: 4px 4px;
            margin-bottom: 4px;
            border: 1px solid $error-green-500;
            color: $error-green-500;

            &.non_refundable {
              border: 1px solid $blue-500;

              .feature-text {
                color: $blue-500;
              }
            }

            .feature-text {
              @extend .tag;
              margin-left: 6px;
            }
          }
        }

        .how-to-link {
          cursor: pointer;
          @extend .button-text-l;
          color: $primary-500;
          display: flex;
          justify-content: center;
          margin-bottom: 15px;

          .icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .app-button {
    width: fit-content;
    margin-top: 16px;
  }
}
